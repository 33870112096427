import React, { Component } from "react";
import './cities.css';

class Cities extends Component {
  state = {
    selectedCity: "Алматы",
    isListOpen: false,
    cities: [
      { id: 1, name: "Алматы", nameOnSelect: "Алматы" },
      { id: 2, name: "Москва", nameOnSelect: "Москвы" },
      { id: 3, name: "Шымкент", nameOnSelect: "Шымкента" },
      { id: 4, name: "Костанай", nameOnSelect: "Костаная" },
      { id: 5, name: "Павлодар", nameOnSelect: "Павлодара" },
      { id: 6, name: "Усть-Каменогорск", nameOnSelect: "Усть-Каменогорска" },
      { id: 7, name: "Омск", nameOnSelect: "Омска" },
      { id: 8, name: "Киев", nameOnSelect: "Киева" },
      { id: 9, name: "Бишкек", nameOnSelect: "Бишкека" },
      { id: 10, name: "Туркестан", nameOnSelect: "Туркестана" },
      { id: 11, name: "Астана", nameOnSelect: "Астаны" },
      { id: 12, name: "Караганда", nameOnSelect: "Караганды" },
      { id: 13, name: "Актобе", nameOnSelect: "Актобе" },
      { id: 14, name: "Актау", nameOnSelect: "Актау" },
      { id: 15, name: "Уральск", nameOnSelect: "Уральска" },
      { id: 16, name: "Атырау", nameOnSelect: "Атырау" },
      { id: 17, name: "Новосибирск", nameOnSelect: "Новосибирска" },
      { id: 18, name: "Тараз", nameOnSelect: "Тараза" },
      { id: 19, name: "Петропавловск", nameOnSelect: "Петропавловска" }
    ]
  };

  // Открытие списка городов
  onOpenList = () => {
    this.setState({
      isListOpen: !this.state.isListOpen
    });
  };

  // Выбор города 
  onSelectCity = (city) => {
    this.setState({
      isListOpen: false,
      selectedCity: city
    });
  };

  // Счет и выставление как должно выглядеть название города на странице
  sliceCityName = city => {
    if (city.length > 11) {
      return city.slice(0, 11) + "...";
    }
    return city;
  };

  render() {
    const { selectedCity, cities, isListOpen } = this.state;

    // Массив элементов городов
    const citiesElements = cities
      .reduce((acc, city, index) => {
        if (index % 10 === 0) {
          acc.push([]);
        }

        const lastIndex = acc.length - 1;
        const element = (
          <p
            key={city.id}
            onClick={e => this.onSelectCity(city.nameOnSelect, e)}
          >
            {city.name}
          </p>
        );

        acc[lastIndex].push(element);

        return acc;
      }, [])
      .map((group, index) => <div key={index}>{group}</div>);

    return (
      <div className="filter" onClick={this.onOpenList}>
        <span>откуда:</span> <br />
        <p>из {this.sliceCityName(selectedCity)}</p>
        <div className={`list ${isListOpen ? "" : "hide"}`}>
          {citiesElements}
        </div>
      </div>
    );
  }
}

export default Cities;