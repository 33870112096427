import React, { Component } from "react";
import './tourists.css';

import Adults from "./adults/adults";
import Children from "./children/children";

class Tourists extends Component {
  state = {
    selectedAdults: '2 взрослых',
    selectedChildren: '',
    isListOpen: false
  };

  // Открытие добвления туристов
  onOpenList = (e) => {
    const isSpanClicked = e.target.tagName === "SPAN" && e.target.innerText === "туристов:";
    const isParagraphClicked = e.target.tagName === "P" && e.target.innerText !== "Убрать ребенка";
  
    if (e.target === e.currentTarget || isSpanClicked || isParagraphClicked) {
      this.setState({
        isListOpen: !this.state.isListOpen
      });
    }
  };
  
  // Взрослый в взр
  sliceAdultsName = tourist => {
    if (tourist.length === 10) {
      return tourist.slice(0, 5);
    } else {
      return tourist.slice(0, 6);
    }
  };

  // Функция для обновления selectedAdults, когда выбран турист в компоненте Adults
  onSelectAdults = (selectedTourist) => {
    this.setState({
      selectedAdults: selectedTourist
    });
  };

  // Функция для обновления selectedChildren, когда выбран турист в компоненте Children
  onSelectChildren = (selectedTourist) => {
    this.setState({
      selectedChildren: selectedTourist
    });
  };
  
  render() {
    const {selectedAdults, selectedChildren, isListOpen} = this.state;

    return (
      <div className="filter" onClick={e => this.onOpenList(e)}>
        <span>туристов:</span> <br />
        <p>{this.sliceAdultsName(selectedAdults)}{selectedChildren}</p>
        <div className={`list ${isListOpen ? "" : "hide"}`}>
          <Adults onSelectAdults={this.onSelectAdults} />
          <Children onSelectChildren={this.onSelectChildren}/>
        </div>
      </div>
    );
  }
}

export default Tourists;