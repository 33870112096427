import React, { useState } from 'react';
import './days.css'; // Подключаем файл стилей

const Days = () => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [isListOpen, setIsListOpen] = useState(false);

  const handleNumberClick = number => {
    if (!selectedRange) {
      setSelectedRange({ start: number, end: number });
    } else if (selectedRange.start === selectedRange.end) {
      if (number < selectedRange.start) {
        setSelectedRange({ start: number, end: selectedRange.start });
      } else {
        setSelectedRange({ start: selectedRange.start, end: number });
      }
    } else if (number < selectedRange.start || number > selectedRange.end) {
      setSelectedRange({ start: number, end: number });
    } else {
      const newStart = number < selectedRange.start ? number : selectedRange.start;
      const newEnd = number > selectedRange.end ? number : selectedRange.end;
      setSelectedRange({ start: newStart, end: newEnd });
    }
  };

  const generateNumbers = () => {
    const numbers = [];
    for (let i = 3; i <= 15; i++) {
      numbers.push(i);
    }
    return numbers;
  };

  const onOpenList = () => {
    setIsListOpen(!isListOpen);
  };

  return (
    <div className="filter">
      <span onClick={onOpenList}>дней:</span> <br />
      <p onClick={onOpenList}>
        {selectedRange === null ? '7-9 дней' : (
          selectedRange.start === selectedRange.end ? `${selectedRange.start} дней` : `${selectedRange.start} - ${selectedRange.end} дней`
        )}
      </p>
      {isListOpen && (
        <div className='list'>
          <div className="number-range">
            {generateNumbers().map(number => (
              <span
                key={number}
                className={`number ${
                  selectedRange &&
                  number >= selectedRange.start &&
                  number <= selectedRange.end
                    ? 'selected'
                    : ''
                }`}
                onClick={() => handleNumberClick(number)}
              >
                {number}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Days;