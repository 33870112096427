import React, { Component } from "react";
import "./children.css";

class Children extends Component {
  constructor (props) {
    super(props);

    this.state = {
      touristsChildren: [],
      tourists: [
        { id: 0, name: "до 1 года" },
        { id: 1, name: "1 год" },
        { id: 2, name: "2 года" },
        { id: 3, name: "3 года" },
        { id: 4, name: "4 года" },
        { id: 5, name: "5 лет" },
        { id: 6, name: "6 лет" },
        { id: 7, name: "7 лет" },
        { id: 8, name: "8 лет" },
        { id: 9, name: "9 лет" },
        { id: 10, name: "10 лет" },
        { id: 11, name: "11 лет" },
        { id: 12, name: "12 лет" },
        { id: 13, name: "13 лет" },
        { id: 14, name: "14 лет" },
        { id: 15, name: "15 лет" }
      ]
    };
  }

  // Вызывается при выборе туриста
  onSelectTourist = (blockIndex, tourist, e) => {
    e.stopPropagation();
    const { touristsChildren } = this.state;
    const updatedChildren = [...touristsChildren];
    updatedChildren[blockIndex] = {
      ...updatedChildren[blockIndex],
      isListOpen: false,
      selectedTourist: tourist
    };
    this.setState({
      touristsChildren: updatedChildren
    });
  
    // Исправлено: Передача выбранного туриста обратно в компонент Tourists
    const selectedChildren = updatedChildren
      .filter((child) => child.selectedTourist !== "выберите")
      .map((child) => child.selectedTourist);
    this.props.onSelectChildren(', ' + selectedChildren.length + ' дет');
  };
  

  // Открывает/закрывает список туристов
  onOpenTour = (blockIndex) => {
    const { touristsChildren } = this.state;
    const updatedChildren = [...touristsChildren];
    updatedChildren[blockIndex] = {
      ...updatedChildren[blockIndex],
      isListOpen: !updatedChildren[blockIndex].isListOpen
    };
    this.setState({
      touristsChildren: updatedChildren
    });
  };

  // Добавляет нового ребенка
  onAddChild = () => {
    const { touristsChildren } = this.state;
    const updatedChildren = [...touristsChildren];
    updatedChildren.push({
      selectedTourist: "выберите",
      isListOpen: false,
    });
    this.setState({
      touristsChildren: updatedChildren,
    });
  };

  // Удаляет ребенка по индексу
  onRemoveChild = (blockIndex) => {
    const { touristsChildren } = this.state;
    const updatedChildren = [...touristsChildren];

    console.log(updatedChildren.length);

    updatedChildren.splice(blockIndex, 1);
    console.log('Индекс' + blockIndex);

    this.setState({
      touristsChildren: updatedChildren
    });
    
    console.log(updatedChildren.length);
    console.log(this.state.touristsChildren.length);
    console.log(this);
  };

  render() {
    const { touristsChildren, tourists } = this.state;

    return (
      <div className="tourists_little_selects">
        <p>Возраст детей:</p>
        <div className="tourists_little">
          {touristsChildren.map((child, blockIndex) => {
            const touristsElements = tourists.map((tourist) => (
              <p
                key={tourist.id}
                onClick={(e) => this.onSelectTourist(blockIndex, tourist.name, e)}
              >
                {tourist.name}
              </p>
            ));

            touristsElements.unshift(
              <p
                key="delete"
                className="delite"
                onClick={function () {this.onRemoveChild(blockIndex)}}
              >
                Убрать ребенка
              </p>
            );

            return (
              <div
                className="tourists_big"
                key={blockIndex}
                onClick={() => this.onOpenTour(blockIndex)}
              >
                {child.selectedTourist}
                <div className={`listLittle ${child.isListOpen ? "" : "hide"}`}>
                  {touristsElements}
                </div>
              </div>
              
            );
          })}
          <button onClick={this.onAddChild}>Добавить ребенка</button>
        </div>
      </div>
    );
  }
}

export default Children;