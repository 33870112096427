import React, { Component } from "react";
import './stars.css';

class Stars extends Component {
  state = {
    selectedStar: "1-5 звезд",
    isListOpen: false,
    stars: [
      { id: 1, name: "1 звезда"},
      { id: 2, name: "2 звезды"},
      { id: 3, name: "3 звезды"},
      { id: 4, name: "4 звезды"},
      { id: 5, name: "5 звезд"},
      { id: 6, name: "1-5 звезд" }
    ]
  };

  // Открытие списка звезд
  onOpenList = () => {
    this.setState({
      isListOpen: !this.state.isListOpen
    });
  };

  // Выбор звезды 
  onSelectstar = (star) => {
    this.setState({
      isListOpen: false,
      selectedStar: star
    });
  };

  render() {
    const { selectedStar, stars, isListOpen } = this.state;

    // Массив элементов звезд
    const starsElements = stars
      .reduce((acc, star, index) => {
        if (index % 10 === 0) {
          acc.push([]);
        }

        const lastIndex = acc.length - 1;
        const element = (
          <p
            key={star.id}
            onClick={e => this.onSelectstar(star.name, e)}
          >
            {star.name}
          </p>
        );

        acc[lastIndex].push(element);

        return acc;
      }, [])
      .map((group, index) => <div className={`list ${isListOpen ? "" : "hide"}`} key={index}>{group}</div>);

    return (
      <div className="filter" onClick={this.onOpenList}>
        <span>звезд:</span> <br />
        <p>{selectedStar}</p>
        {starsElements}
      </div>
    );
  }
}

export default Stars;