import React, { Component } from "react";
import './adults.css';

class Adults extends Component {
  state = {
    selectedTourist: "2 взрослых",
    isListOpen: false,
    tourists: [
      { id: 1, name: "1 взрослый"},
      { id: 2, name: "2 взрослых"},
      { id: 3, name: "3 взрослых"},
      { id: 4, name: "4 взрослых"},
      { id: 5, name: "5 взрослых"},
      { id: 6, name: "6 взрослых" },
      { id: 7, name: "7 взрослых" },
      { id: 8, name: "8 взрослых" },
      { id: 9, name: "9 взрослых" },
      { id: 10, name: "10 взрослых" }
    ]
  };

  // Выбор туриста 
  onSelectTourist = (tourist, e) => {
    e.stopPropagation();
    this.setState({
      isListOpen: false,
      selectedTourist: tourist
    });

    // Передача выбранного туриста обратно в компонент Tourists
    this.props.onSelectAdults(tourist);
  };

  // Открытие списка туристов
  onOpenTour = () => {
    this.setState({
      isListOpen: !this.state.isListOpen
    });
  };

  render() {
    const { selectedTourist, tourists, isListOpen } = this.state;

    const touristsElements = tourists
      .reduce((acc, tourist) => {
        const lastIndex = acc.length - 1;
        const element = (
          <p
            key={tourist.id}
            onClick={e => this.onSelectTourist(tourist.name, e)}
          >
            {tourist.name}
          </p>
        );

        acc[lastIndex].push(element);

        return acc;
      }, [[]])
      .map((group, index) => (
        <div className={`listLittle ${isListOpen ? "" : "hide"}`} key={index}>
          {group}
        </div>
      ));

    return (
      <div className="tourists_big_selects">
        <p>Взрослых:</p>
        <div className="tourists_big" onClick={this.onOpenTour}>
          {selectedTourist}
          {touristsElements}
        </div>
        <div className="border"></div>
      </div>
    );
  }
}

export default Adults;