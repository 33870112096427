import React from "react";
import "./filters.css";

import Cities from "../cities/cities";
import Stars from "../stars/stars";
import Tourists from "../tourists/tourists";
import Days from "../days/days";
import DateFilter from "../date/date";

const Filters = () => {
  return (
    <div className="filters">
      <Cities/>
      <div className="filter">
        <span>страна, курорт или отель:</span> <br />
        <p>Страна/курорт/отель</p>
      </div>
      <DateFilter/>
      <Days/>
      <Stars/>
      <Tourists/>
      <button>найти</button>
    </div>
  );
};

export default Filters;