import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

import './date.css';

const CustomDatePicker = ({ selectedDate, handleDateChange }) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="dd.MM"
      className="custom-datepicker"
      locale={ru}
    />
  );
};

const DateFilter = () => {
  const [selectedDate, setSelectedDate] = useState(new Date()); // Устанавливаем текущую дату

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="filter">
      <span>дней:</span> <br />
      <CustomDatePicker selectedDate={selectedDate} handleDateChange={handleDateChange}/>
    </div>
  );
};

export default DateFilter;