class KompasServices {
    _apiBase = 'http://parser1.kompastour.com/export/default.php';
    _apiKey = 'oauth_token=be4ee0e0263344588af944cc6b8aedfb';
    _typeApi = 'type=json';

    getResource = async () => {
        const url = `${this._apiBase}?${this._apiKey}&${this._typeApi}&action=SearchTour_TOWNFROMS`;
        let res = await fetch(url);
    
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}, status: ${res.status}`);
        }
    
        return await res.json();
    }
}

export default KompasServices;