import React, { Component } from "react";
import Filters from "./components/filters/filters";

import "./app.css";

import KompasServices from "./services/kompas_services";

const kompasService = new KompasServices();

kompasService.getResource()
  .then(data => {
    console.log(data);
  })
  .catch(error => {
    console.error(error);
  });

class App extends Component {
  render() {
    return (
      <>
        <Filters/>
      </>
    );
  }
}

export default App;